import classes from "./MainScreen.module.css";
import { Link } from "react-router-dom";

function MainScreen() {
  return (
    <div>
      <div className={classes.section}>
        <Link to="/about" style={{ textDecoration: "none", color: "#000" }}>
          <section>
            <h1 className={classes.titleText}>About Me</h1>
          </section>
        </Link>
      </div>

      <div className={classes.section}>
        <Link to="/resume" style={{ textDecoration: "none", color: "#000" }}>
          <section>
            <h1 className={classes.titleText}>Resume</h1>
          </section>
        </Link>
      </div>

      <div className={classes.section}>
        <Link to="/contact" style={{ textDecoration: "none", color: "#000" }}>
          <section>
            <h1 className={classes.titleText}>Contact</h1>
          </section>
        </Link>
      </div>
    </div>
  );
}

export default MainScreen;
