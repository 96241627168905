import classes from './AboutMe.module.css';
import logo2 from '../../logo2.png';

function AboutMe() {
    return (
        <div>
            <div className={classes.aboutImage}>
                <img src={logo2} alt='aboutme'></img>
            </div>

            <div className={classes.aboutMe}>
                re
            </div>
        </div>
    );
}

export default AboutMe;