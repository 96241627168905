import {Document, Page} from 'react-pdf';
import resume from '../../../src/asset/Nabil_Hamid_Resume.pdf';

function Resume() {
  return (
    <Document 
    file={resume}
    options={{ workerSrc: "/pdf.worker.js" }}>
      <Page pageNumber={1}></Page>
    </Document>
  )
  // return (
  //   <section className={classes.resume}>
  //     <h1>Resume page </h1>

  //     <div>
  //       <h3 className={classes.sectionTitle}>Summary</h3>
  //       <p>
  //         User Experience Engineer with 5+ years of experience in developing and
  //         implementing functionalities into web applications in an Agile
  //         environment using Angular, ActionScript/Flex, Java, and JavaScript.
  //         Have experience in maintenance and support of a live web application.
  //       </p>
  //       <p>
  //         Able to multitask and work within teams and independently to ensure
  //         deliverables are completed efficiently. Experience in Agile
  //         development environment. Have experience in Health & Public Service
  //         industry Passionate about new technologies to help improve current
  //         technical skills and gaining new skills in order to become a better
  //         full-stack engineer.
  //       </p>
  //     </div>
  //     <div>
  //       <h3 className={classes.sectionTitle}>Skills</h3>
  //       <ul>
  //         <li className={classes.skills}>HTML5, CSS, JavaScript</li>
  //         <li className={classes.skills}>
  //           Angular, TypeScript, ActionScript, Flex
  //         </li>
  //         <li className={classes.skills}>PL/SQL, Java, Node JS</li>
  //         <li className={classes.skills}>MongoDB, ReactJS</li>
  //         <li className={classes.skills}>
  //           Web Application Development, Agile Developmentt
  //         </li>
  //         <li className={classes.skills}>
  //           Debugging and Analysis, Test-Script Writing, Detail
  //           Oriented,Organized
  //         </li>
  //       </ul>
  //     </div>

  //     <div>
  //       <h3 className={classes.sectionTitle}>Experience</h3>

  //       <div>
  //         <h4 className={classes.skills}>
  //           <b>
  //             User Experience Engineering Senior Analyst Accenture － San
  //             Francisco, CA
  //           </b>
  //         </h4>
  //         <h6>
  //           <ul className={classes.listStyle}>
  //             <li>User Experience Engineering Analyst (Dec 2017 – Nov 2019)</li>
  //             <li>
  //               User Experience Engineering Associate (Oct 2015 – Nov 2017)
  //             </li>
  //             <li>Software Engineering Associate (Oct 2014 – Oct 2015)</li>
  //           </ul>
  //         </h6>
  //       </div>

  //       <ul className={classes.experienceStyle}>
  //         <li>
  //           Lead of a team with 2 junior developers to help with the maintenance
  //           and support of a client web application.
  //         </li>
  //         <li>
  //           Collaborate with testing team, client business and product owners to
  //           ensure deliverables met expectations of the client and are delivered
  //           on time.
  //         </li>
  //         <li>
  //           Learned and used Angular, ActionScript, Flex, and JavaScript to
  //           develop and modify User Interface screens and functionality for a
  //           client web application.
  //         </li>
  //         <li>
  //           Worked on telephony feature of web application help process calls
  //           and gather information.
  //         </li>
  //         <li>
  //           Troubleshoot and resolve live production issues of the application
  //           and communicate feasibilities of enhancements and fixes of issues.
  //         </li>
  //         <li>
  //           Created and executed test scripts of new functionalities for web
  //           applications  Helped with several deployments of application to
  //           live environment.
  //         </li>
  //       </ul>

  //       <p></p>
  //     </div>

  //     <div>
  //       <h3 className={classes.sectionTitle}>Education</h3>
  //     </div>
  //     <div>
  //       <h3 className={classes.sectionTitle}>Certifications</h3>
  //     </div>
  //   </section>
  // );
}

export default Resume;
