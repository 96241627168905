
import Footer from './Footer';
import classes from './Layout.module.css';
import Navigation from './Navigation';


function Layout(props) {

    return (
        <div className={classes.layoutStyle}>
            <Navigation></Navigation>
            <main className={classes.main}>
                {props.children}
            </main>
            <Footer></Footer>
        </div>
    );
}

export default Layout;