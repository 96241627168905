import React from 'react';
import { Switch, Route } from "react-router-dom";
import './App.css';
//import WebServices from './services/services';
import Layout from './components/layout/Layout';
import MainScreen from './components/screens/MainScreen';
import Resume from './components/screens/Resume';
import Contact from './components/screens/Contact';
import AboutMe from './components/screens/AboutMe';

function App() {

  // const [data, setData] = React.useState(null);

  // React.useEffect(() =>{
  //   WebServices.getInfo()
  //   .then( res => {
  //     console.log(res.data);
  //     setData(res.data.restaurants);
  //   })
  //   .catch( e =>{
  //     console.log(e)
  //   })
  // }, []);

  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route path='/' exact={true}>
              <MainScreen></MainScreen>  
          </Route>
          <Route path='/about'>
            <AboutMe></AboutMe>
          </Route>
          <Route path='/resume'>
            <Resume></Resume>
          </Route>
          <Route path='/contact'>
            <Contact></Contact>
          </Route>
        </Switch>              
      </Layout>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          {!data ? <p>Loading..</p> : data.map( restaurant => <p>{restaurant.name}</p> )}
        </div>        
      </header> */}
    </div>
  );
}

export default App;
