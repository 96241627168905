import classes from "./Contact.module.css";

function Contact() {
  return (
    <div>
      <form>
        <div className={classes.label}>
          <label htmlFor="name" >Name:</label>
          <input type="text" id="name" required className={classes.input} />
        </div>
        <div className={classes.label}>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" required className={classes.input} />
        </div>
        <div className={classes.label}>
          <label htmlFor="message">Message:</label>
          <textarea id="message" required className={classes.textarea} />
        </div>
        <button type="submit" className={classes.button}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default Contact;
