import classes from './Navigation.module.css';
import logo2 from '../../logo2.png';
import { BsFillHouseDoorFill } from "react-icons/bs";
import { Link } from 'react-router-dom';


function Navigation() {

    return (
        <header className={classes.header}>            
            <div className={classes.image}>
                <img src= {logo2} alt='Logo'></img>                
            </div>

            <nav>
                <ul>
                   <Link to='/' style={{ color:'#000'}}><li><BsFillHouseDoorFill className={classes.home} /></li></Link> 
                </ul>
            </nav>

        </header>

    );
}

export default Navigation;