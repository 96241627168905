import classes from './Footer.module.css';


function Footer() {
    return (
        <footer className={classes.footerStyle}>
           <h5>Copyright &copy; 2022 Nabil Hamid</h5> 
        </footer>
    );
}

export default Footer;